// Taken from: https://github.com/angeloocana/gatsby-plugin-i18n/issues/72#issuecomment-526835685

import React from 'react'
import { navigate } from 'gatsby'
import { withPrefix } from 'gatsby-link'

class RedirectIndex extends React.PureComponent {
  constructor(args) {
    super(args)
    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      navigate(withPrefix('/zh/'))
    }
  }

  render() {
    return <div />
  }
}

export default RedirectIndex
